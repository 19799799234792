import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { SPEAKER_ROLE_FOR_SESSION_FRAGMENT } from '@/graphql/_Fragments/SpeakerRole/ForSession';

export const SESSION_CHAT_FRAGMENT = `
  fragment sessionChatFragment on Session {
    uid
    id
    schemaCode
    name
    startTime
    startTimestamp
    endTime
    endTimestamp
    imageFileResource {
      ...fileResourceBaseFragment
    }
    speakerRoles {
      ...speakerRoleForSessionFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${SPEAKER_ROLE_FOR_SESSION_FRAGMENT}
`;
